<template>
  <div id="box_style3_content">
    <div id="box_style3_title">{{ this.$props.item.title }}</div>
    <div id="box_style3_text_content">
      <img id="box_style3_icon" src="@/assets/doubleQuotes.png" />
      <div
        v-for="(des, index) in this.$props.item.data"
        :key="index"
        id="box_style3_text"
      >
        {{ des }}
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: Object,
  },
})
export default class BoxWithStyle3 extends Vue {}
</script>

<style scoped>
@import url("../../less/pc/Boxs/index.less");
</style>