<template>
  <div class="pc_page_container_all">
    <div id="p_prodev_header">
      <div id="p_prodev_header_title">{{ info.header.title }}</div>
      <div id="p_prodev_header_des">{{ info.header.des }}</div>
    </div>
    <div id="p_prodev_partner">
      <div id="p_prodev_partner_title">{{ info.partner.title }}</div>
      <div id="p_prodev_partner_list">
        <box-with-style-3
          v-for="(item, index) in info.partner.list"
          :key="index"
          :item="item"
        />
      </div>
    </div>
    <div id="p_prodev_factory">
      <div id="p_prodev_factory_title">{{ info.factory.title }}</div>
      <div id="p_prodev_factory_list">
        <div
          v-for="(item, index) in info.factory.list"
          :key="index"
          id="p_prodev_factory_list_content"
        >
          <div id="p_prodev_factory_list_title">{{ item.title }}</div>
          <box-with-style-1 :item="item" />
        </div>
      </div>
      <div id="p_prodev_factory_img_content">
        <news-page :items="info.factory.img.list" />
        <div id="p_prodev_factory_img_des">{{ info.factory.img.des }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { kProdevPageContentInfo } from "@/config/prodev";
import BoxWithStyle1 from "@/components/PC/BoxWithStyle1.vue";
import BoxWithStyle3 from "@/components/PC/BoxWithStyle3.vue";
import NewsPage from "@/components/PC/NewsPage.vue";

@Options({
  components: {
    BoxWithStyle1,
    BoxWithStyle3,
    NewsPage,
  },
  data() {
    return {
      info: kProdevPageContentInfo,
    };
  },
})
export default class Recruitment extends Vue {}
</script>

<style scoped>
@import url("../../../less/pc/page.less");
@import url("../../../less/pc/Prodev/index.less");
</style>